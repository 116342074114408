<script setup lang="ts">
const props = defineProps({
  image: {
    type: String,
    required: true,
  },
  height: {
    type: Number,
    default: 100,
  },
})
</script>

<template>
  <header
    class="relative flex items-center text-white bg-cover bg-no-repeat bg-center min-h-[500px]"
    :style="{ backgroundImage: `url(${props.image})`, height: `${props.height}vh` }"
  >
    <div class="absolute z-10 inset-0">
      <slot />
    </div>
  </header>
</template>
